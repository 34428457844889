<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create RTB Zone</strong>
            </div>
            <div class="text-muted">Create new RTB Zone</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Publisher of the RTB Zone.</span>
                      </template>
                      <a-select @change="domainHandler"
                        v-decorator="['PublisherId', { initialValue: initialValue, rules: [{ required: true, message: 'Please select publisher' }] }]"
                        placeholder="Please select a publisher" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option v-for="pub in ORTBpublisher.list.filter(ele => ele.Status === 0)"
                          :value="pub.Id" :key="pub.Id">[{{
              pub.Id }}] {{ pub.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>RTB Zone's name.</span>
                      </template>
                      <a-input type="text" placeholder="RTB Zone Name" v-decorator="['name', {
              rules: [
                { required: true, message: 'Name is required.' },
                { max: 64, message: 'Maximum 64 characters allowed.' }
              ]
            }]" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Platform Domain">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The canonical domain name of the advertising system that is making bid requests to this
                          RTB
                          zone. It is used for checking if the system is publisher’s authorized seller of inventory, by
                          checking publisher’s ads.txt or app-ads.txt file.</span>
                      </template>
                      <a-input type="text" v-decorator="['platformDomain', {
              initialValue: ptDomain ? ptDomain : '', rules: [{ max: 32, message: 'Maximum 32 characters allowed.' },
              { pattern: domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com' }]
            }]" placeholder="Advertising Platform Domain" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="sellers.json Domain">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The canonical domain where advertising system hosts its sellers.json file. Usually it is
                          the
                          same domain as Platform Domain.</span>
                      </template>
                      <a-input type="text" v-decorator="['sellersJsonDomain', {
              initialValue: jsonDomain ? jsonDomain : '', rules: [{ max: 32, message: 'Maximum 32 characters allowed.' },
              { pattern: domainRegExp, message: '\'www.\', \'http://\' and \'https://\' are not allowed. Please input pure top-level domain, for example: google.com' }]
            }]" placeholder="sellers.json Domain" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Margin">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Margin percentage to add to bid floor which is sent to advertiser's Remote RTB Zone. For
                          example, if publisher's bid floor is $5 and Margin is 20%, advertiser will receive $6 bid
                          floor.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['margin', { initialValue: 0 }]"
                        placeholder="Margin">
                      </a-input>
                      <!-- <a-input  /> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher Revenue Share">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Percentage of a revenue (bid price minus bid floor) from Remote RTB Zone's advertiser that
                          RTB
                          zone's publisher earns.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%"
                        v-decorator="['publisherRevShare', { initialValue: 100 }]"
                        placeholder="Publisher Revenue Share">
                      </a-input>
                      <!-- <a-input  /> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impression Tracking Method">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>How impressions are tracked.</span>
                      </template>
                      <a-select v-decorator="['trackingMethod', { initialValue: 2 }]"
                        placeholder="Impression Tracking Method" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option :value="0">
                          nurl
                        </a-select-option>
                        <a-select-option :value="1">
                          burl
                        </a-select-option>
                        <a-select-option :value="2">
                          Markup Display
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. QPS">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum queries per second - Maximum number of requests to receive from the RTB Zone in
                          one
                          second. Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Max. QPS"
                               v-decorator="['maxQps', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>

                </div>
                <div class="w-100">

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Check ads.txt">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Check ads.txt for the site.</span>
                      </template>
                      <a-select v-decorator="['checkAdsTxt', { initialValue: 0 }]" placeholder="Check ads.txt"
                        :showSearch="true" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value="0">
                          No
                        </a-select-option>
                        <a-select-option :value="1">
                          Yes, allow supplier until crawled
                        </a-select-option>
                        <a-select-option :value="2">
                          Yes, deny supplier until crawled
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Check app-ads.txt">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Check app-ads.txt for the app.</span>
                      </template>
                      <a-select v-decorator="['checkAppAdsTxt', { initialValue: 0 }]" placeholder="Check app-ads.txt"
                        :showSearch="true" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value="0">
                          No
                        </a-select-option>
                        <a-select-option :value="1">
                          Yes, allow supplier until crawled
                        </a-select-option>
                        <a-select-option :value="2">
                          Yes, deny supplier until crawled
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Check sellers.json">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Check sellers.json for the app.</span>
                      </template>
                      <a-select v-decorator="['checkSellersJson', { initialValue: 0 }]" placeholder="Check sellers.json"
                        :showSearch="true" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value="0">
                          No
                        </a-select-option>
                        <a-select-option :value="1">
                          Yes, allow supplier until crawled
                        </a-select-option>
                        <a-select-option :value="2">
                          Yes, deny supplier until crawled
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domains/Brands Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of domains or brand names to block.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="adDomainsBrandsBlacklist" placeholder="Ad Domain/Brands Blacklist"
                          :showSearch="true" :filterOption="true" optionFilterProp="children" mode="tags"
                          :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('brands')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Apps Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Blacklist of applications by their application identifiers. On Android, these should be
                          bundle or package names (e.g., com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="adAppsBlacklist" placeholder="Ad Apps Blacklist" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('apps')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auto Optimize">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Automatic requests optimization.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="autoOpt" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pop adm XML">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable XML in adm field in bid response for Pop ads.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="popAdmXml" />
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to receive per day. The system will even out distribution of requests
                          throughout
                          the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the
                          day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Limit Per IP">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Daily limit of impressions per IP address. Set to 0 for unlimited impressions per IP address.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsLimitPerIp', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions
                          throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on RTB Zone. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Spent Daily Limit"
                        v-decorator="['spentDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Filters</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Banner Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Banner Ads.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="bannerEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Video Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Video Ads.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="videoEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Audio Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Audio Ads.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="audioEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Native Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Native Ads.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="nativeEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pop Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Pop Ads.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="popEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Web Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Web traffic.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="webEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Enabled">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable App traffic.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="appEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Min. Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Allow bid request only if Bidfloor is greater than this value.</span>
                      </template>
                      <a-input type="float" min="0" max="100" step="0.0001" placeholder="Min. Bidfloor"
                        v-decorator="['minBidfloor', { initialValue: 0, rules: [{ validator: minBidValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Allow bid request only if Bidfloor is less than this value.</span>
                      </template>
                      <a-input type="float" min="0" max="100" step="0.0001" placeholder="Max. Bidfloor"
                        v-decorator="['maxBidfloor', { initialValue: 20, rules: [{ validator: maxBidValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol"
                    label="Domains/Brands List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher domains or brand names.</span>
                      </template>
                      <a-select v-decorator="['domainsBrandsListMode', { initialValue: 0 }]"
                        placeholder="Domains/Brands List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Domains/Brands List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Domain Lists to block or allow.</span>
                      </template>
                      <a-select v-decorator="['DomainsBrandsList', { initialValue: [] }]"
                        placeholder="Please Select Domains/Brands Lists" :showSearch="true" :filterOption="true"
                        mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeDomainLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                      <!-- <div class="list-flex">
                        <a-select v-model="domainsBrandsList" placeholder="Domains/Brands List" :showSearch="true"
                          :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-secondary copy-button-width" @click="copyList('brands')"><i
                            class="fa fa-copy"></i></a-button>
                      </div> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Apps List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher applications by their application identifiers.</span>
                      </template>
                      <a-select v-decorator="['appsListMode', { initialValue: 0 }]" placeholder="Apps List Mode"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Apps List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of App Lists to block or allow.</span>
                      </template>
                      <a-select v-decorator="['AppsList', { initialValue: [] }]" placeholder="Please Select App Lists"
                        :showSearch="true" :filterOption="true" mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeAppLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                      <!-- <div class="list-flex">
                        <a-select v-model="appsList" placeholder="Apps List" :showSearch="true" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('apps')"><i
                            class="fa fa-copy"></i>
                        </a-button>
                      </div> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Sizes List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow creatives' sizes.</span>
                      </template>
                      <a-select v-model="sizesListMode" placeholder="Blacklist" defaultValue="0" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option value='0'>Blacklist</a-select-option>
                        <a-select-option value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Non Empty Referer">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Ad impression or click with no referer will be filtered.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled"
                        v-model="nonEmptyRefererEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Referer Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Referer in ad impression or click and site URL should match.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled"
                        v-model="refererMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's IP address in ad impression or click and user IP parameter should
                          match.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="ipMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="UA Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's User Agent in ad impression or click and user agent parameter should
                          match.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="uaMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item class="mt-10" :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol"
                    label="Sizes List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of creatives' sizes to block or allow.</span>
                      </template>
                      <a-select v-decorator="['sizesList']" placeholder="Please select Sizes" :showSearch="true"
                        :filterOption="true" mode="multiple" optionFilterProp="children">
                        <a-select-option :value="`${size.size.width}x${size.size.height}`"
                          v-for="(size, index) in sizes" :key="index">{{ size.size.width }}x{{ size.size.height }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Pub ID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Pub IDs.</span>
                      </template>
                      <a-select v-decorator="['pubIdListMode', { initialValue: 0 }]" placeholder="Pub ID List Mode"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Pub ID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Pub IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="pubIdList" placeholder="Pub ID List" :showSearch="true" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('pubIds')"><i
                            class="fa fa-copy"></i>
                        </a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="CRID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Creative IDs</span>
                      </template>
                      <a-select v-decorator="['cridListMode', { initialValue: 0 }]" placeholder="CRID List Mode"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="CRID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Creative IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="cridList" placeholder="CridList" :showSearch="true" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('crids')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="IP Blacklists">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of IP Lists to use for IP block. Only "Not Global" IP Lists can be chosen.</span>
                      </template>
                      <a-select v-decorator="['ipBlacklists', { initialValue: [] }]"
                        placeholder="Please select IP Lists" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in activeIpLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Categories Filter</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Enable IAB Categories">
                <a-tree checkable v-model="checkedKeys" :auto-expand-parent="autoExpandParent"
                  :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                  optionFilterProp="children" />
              </a-form-item>
              <a-divider style="margin-top: 34px;">Targeting</a-divider>
              <a-tabs default-active-key="1" class="mb-4">
                <a-tab-pane key="1" tab="Countries">
                  <a-row>
                    <a-input-search placeholder="Filter Countries" style="width: 200px" class="mb-2"
                      v-model="filterSearch" />
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half"
                        v-model="displayEnabledCountries" /> Display
                      Only Enabled</label>
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half" v-model="selectAllCountries"
                        @click="handleCountries" /> De/Select All</label>
                  </a-row>
                  <a-spin class="d-flex justify-content-center" v-if="enabledCountries.length === 0">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                  </a-spin>
                  <ul v-else class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li class="list-group-item treeContainer" v-for="(item, index) in enabledCountries" :key="index"
                      :style="item.Enabled ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" v-model="item.Enabled"
                          @click="enableCountry($event, item)" />
                        <span class="col-sm"><img :src="matchIcon(countries, item.CC)" /> {{ item.Country }} ({{
              item.CC
            }})</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Device Types">
                  <ul class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li v-for="item in deviceTypes" :key="item.index" class="list-group-item treeContainer"
                      :style="activeDeviceTypes.includes(item.value) ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" :checked="enabledDevice(item.value)"
                          @click="enableDevice($event, item.value)" />
                        <span class="col-sm">{{ item.name }}</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
              </a-tabs>
              <a-divider>Countries Optimization for last 7 days</a-divider>
              <b-table :bordered="true" :current-page="countriesCurrentPage" :fields="filteredCountriesFields"
                :filter="filter" :hover="true" :items="showCountryOptimization" :per-page="countriesPerPage"
                :small="true" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :striped="true" :tbody-tr-class="rowClass" show-empty stacked="md" @filtered="onFiltered">
                <template #cell(CC)="data">
                  <div class="items-center"><img :src="matchIcon(countries, data.item.CC)" /> {{
              $store.getters['info/countryName'](data.item.CC)
            }}</div>
                </template>
                <template #cell(Requests)="data">
                  {{ $formatNumber(data.item.Requests) }}
                </template>
                <template #cell(Bids)="data">
                  {{ $formatNumber(data.item.Bids) }}
                </template>
                <template #cell(Impressions)="data">
                  {{ $formatNumber(data.item.Impressions) }}
                </template>
              </b-table>
              <b-row>
                <b-col class="my-1" md="6">
                  <p>Showing {{ (countriesCurrentPage - 1) * countriesPerPage }} to {{
              (((countriesCurrentPage - 1) * countriesPerPage) + countriesPerPage > countriesTotalRows ?
                countriesTotalRows : ((countriesCurrentPage - 1) * countriesPerPage) +
                countriesPerPage)
            }} of {{ parseInt(countriesTotalRows).toLocaleString() }} entries</p>
                </b-col>
                <b-col class="my-1 d-flex flex-row-reverse" md="6">
                  <b-pagination v-model="countriesCurrentPage" :per-page="countriesPerPage"
                    :total-rows="countriesTotalRows" class="my-0">
                  </b-pagination>
                </b-col>
              </b-row>
              <a-divider>Sizes Optimization for last 7 days</a-divider>
              <b-table :bordered="true" :current-page="sizesCurrentPage" :fields="filteredSizesFields" :filter="filter"
                :hover="true" :items="showSizesOptimization" :per-page="sizesPerPage" :small="true"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :striped="true"
                :tbody-tr-class="rowClass" show-empty stacked="md" @filtered="onFiltered">
                <template #cell(CC)="data">
                  <div class="items-center"><img :src="matchIcon(countries, data.item.CC)" /> {{
              $store.getters['info/countryName'](data.item.CC)
            }}</div>
                </template>
                <template #cell(Requests)="data">
                  {{ $formatNumber(data.item.Requests) }}
                </template>
                <template #cell(Bids)="data">
                  {{ $formatNumber(data.item.Bids) }}
                </template>
                <template #cell(Impressions)="data">
                  {{ $formatNumber(data.item.Impressions) }}
                </template>
              </b-table>
              <b-row>
                <b-col class="my-1" md="6">
                  <p>Showing {{ (sizesCurrentPage - 1) * sizesPerPage }} to {{
              (((sizesCurrentPage - 1) * sizesPerPage) + sizesPerPage > sizesTotalRows ? sizesTotalRows :
                ((sizesCurrentPage - 1) * sizesPerPage) +
                sizesPerPage)
            }} of {{ parseInt(sizesTotalRows).toLocaleString() }} entries</p>
                </b-col>
                <b-col class="my-1 d-flex flex-row-reverse" md="6">
                  <b-pagination v-model="sizesCurrentPage" :per-page="sizesPerPage" :total-rows="sizesTotalRows"
                    class="my-0">
                  </b-pagination>
                </b-col>
              </b-row>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create RTB Zone</button>
                  <button type="button" @click="$router.push({ name: 'rtbzones' })"
                    class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import { createRTBZone } from '@/api/display/rtbzone'
import { getIabCategories } from '@/api/info'
import zoneSize from '@/views/display/data/zoneSizeRemoteRTBZones.json'
import staticCountryData from '@/resources/countries.json'
import Vue from 'vue'

export default {
  components: {},
  computed: {
    ...mapState(['ORTBpublisher', 'rtbzone']),
    ...mapGetters('ORTBiplist', ['getIpLists', 'getActiveIpLists']),
    ...mapGetters('applist', ['getAppLists', 'getActiveAppLists']),
    ...mapGetters('domainlist', ['getDomainLists', 'getActiveDomainLists']),
    domainsBrandsListString() {
      return this.domainsBrandsList.join('\n')
    },
    appsListString() {
      return this.appsList.join('\n')
    },
    activeAppLists: function () {
      return this.getActiveAppLists.filter(el => el.Global === 0)
    },
    activeDomainLists: function () {
      return this.getActiveDomainLists.filter(el => el.Global === 0)
    },
    pubIdListString() {
      return this.pubIdList.join('\n')
    },
    cridListString() {
      return this.cridList.join('\n')
    },
    activeIpLists: function () {
      return this.getActiveIpLists.filter(el => el.Global === 0)
    },
    adDomainsBrandsBlacklistString() {
      return this.adDomainsBrandsBlacklist.join('\n')
    },
    adAppsBlacklistString() {
      return this.adAppsBlacklist.join('\n')
    },
    filteredCountriesFields() {
      return this.countriesFields.filter(c => c.show)
    },
    countriesTotalRows() {
      return this.showCountryOptimization.length
    },
    enabledCountries() {
      let filteredCountries = this.countriesList
      if (this.displayEnabledCountries) {
        filteredCountries = filteredCountries.filter(el => el.Enabled)
      }
      if (this.filterSearch) {
        filteredCountries = filteredCountries.filter(el =>
          el.Country.toLowerCase().includes(this.filterSearch.toLowerCase()) || el.CC.toLowerCase().includes(this.filterSearch.toLowerCase()),
        )
      }
      return filteredCountries
    },
    showSizesOptimization() {
      // return this.rtbzoneData.OptimizationSizes !== [] ? this.rtbzoneData.OptimizationSizes : []
      return []
    },
    sizesTotalRows() {
      return this.showSizesOptimization.length
    },
    showCountryOptimization() {
      // return this.rtbzoneData.OptimizationCC !== [] ? this.rtbzoneData.OptimizationCC : []
      return []
      // return this.showActive ? this.getActiveRTBZones : this.getRTBZones
    },
    filteredSizesFields() {
      return this.sizesFields.filter(c => c.show)
    },
    initialValue() {
      if (this.$route.params.publisherId) {
        const publisher = this.ORTBpublisher.list.find(ele => ele.Id === parseInt(this.$route.params.publisherId))
        return publisher === undefined ? [] : publisher.Id
      } else {
        return []
      }
    },
  },

  data() {
    return {
      sizes: zoneSize,
      jsonDomain: '',
      ptDomain: '',
      filterSearch: '',
      filter: '',
      sortDirection: 'asc',
      sortBy: 'Bids',
      sortDesc: true,
      domainRegExp: null,
      sizesListMode: undefined,
      bannerEnabled: true,
      videoEnabled: true,
      audioEnabled: true,
      nativeEnabled: true,
      popEnabled: true,
      webEnabled: true,
      appEnabled: true,
      nonEmptyRefererEnabled: true,
      autoOpt: true,
      popAdmXml: false,
      refererMatchEnabled: false,
      ipMatchEnabled: true,
      uaMatchEnabled: true,
      selectAllCountries: true,
      displayEnabledCountries: false,
      sizesCurrentPage: 1,
      countriesCurrentPage: 1,
      sizesPerPage: 50,
      countriesPerPage: 50,
      countries: staticCountryData,
      unformattedResponse: [],
      treeData: [],
      selectedKeys: [],
      countriesList: [],
      tst: [],
      autoExpandParent: false,
      checkedKeys: [],
      adDomainsBrandsBlacklist: [],
      adAppsBlacklist: [],
      pubIdList: [],
      cridList: [],
      form: this.$form.createForm(this),
      deviceTypes: [
        {
          name: 'Desktop',
          value: 1,
        },
        {
          name: 'Mobile',
          value: 2,
        },
        {
          name: 'Tablet',
          value: 3,
        },
        {
          name: 'CTV',
          value: 4,
        },
        {
          name: 'Other',
          value: 5,
        },
      ],
      activeDeviceTypes: [1, 2, 3, 4, 5],
      countriesFields: [
        {
          key: 'CC',
          label: 'Country',
          show: true,
          headerTitle: 'Country',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'Requests',
          label: 'Requests',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Requests',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:100px',
          sortable: true,
        },
        {
          key: 'Bids',
          label: 'Bids',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Bids',
          thClass: 'text-center',
          tdClass: 'text-right',
          sortDirection: 'desc',
          thStyle: 'width:100px',
          sortable: true,
        },
        {
          key: 'Impressions',
          label: 'Impressions',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Impressions',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:110px',
          sortable: true,
        },
      ],
      sizesFields: [
        {
          key: 'Size',
          label: 'Size',
          show: true,
          headerTitle: 'Size',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'Requests',
          label: 'Requests',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Requests',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:100px',
          sortable: true,
        },
        {
          key: 'Bids',
          label: 'Bids',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Bids',
          thClass: 'text-center',
          tdClass: 'text-right',
          sortDirection: 'desc',
          thStyle: 'width:100px',
          sortable: true,
        },
        {
          key: 'Impressions',
          label: 'Impressions',
          show: true,
          formatter: '$formatNumber',
          headerTitle: 'Impressions',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:110px',
          sortable: true,
        },
      ],
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      supplyLabelCol: {
        xs: { span: 18 },
        sm: { span: 5 },
      },
      supplyWrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },

    }
  },

  methods: {
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    handleCountries() {
      this.selectAllCountries = !this.selectAllCountries
      this.countriesList.forEach(el => {
        el.Enabled = this.selectAllCountries
      })
    },
    enabledDevice(value) {
      if (this.activeDeviceTypes.includes(value)) {
        return true
      } else {
        return false
      }
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) {
        return false
      } else {
        const image = cc.image
        return image
      }
    },
    enableCountry(event, row) {
      const itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex !== -1) {
        this.countriesList[itemIndex].Enabled = event.target.checked
      }
    },
    enableDevice(event, value) {
      const index = this.activeDeviceTypes.indexOf(value)
      if (index === -1) {
        this.activeDeviceTypes.push(value)
      } else {
        this.activeDeviceTypes.splice(index, 1)
      }
    },
    populateCountry: function () {
      if (this.countriesList.length > 0 && this.countriesList !== null && this.countriesList.length > 0) {
        for (const index in this.countriesList) {
          const country = this.countriesList.filter(ob => ob.CC === this.countriesList[index].CC)
          if (typeof country[0] !== 'undefined') {
            this.countriesList[index].Enabled = true
          }
        }
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.countriesTotalRows = filteredItems.length
      // this.currentPage = 1
    },
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
    maxBidValidator(rule, value, message) {
      if (value === '') {
        message('Max. Bidfloor can not be empty')
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Max. Bidfloor can not be negative.')
      } else if (parseFloat(value) > 100) {
        message('Max. Bidfloor can not be greater than 100.')
      }
      message()
    },
    minBidValidator(rule, value, message) {
      if (value === '') {
        message('Min. Bidfloor can not be empty')
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Min. Bidfloor can not be negative.')
      } else if (parseFloat(value) > 100) {
        message('Min. Bidfloor can not be greater than 100.')
      } else if (parseFloat(this.form.getFieldValue('maxBidfloor')) < parseFloat(value)) {
        message('Min. Bidfloor can not be greater than Max. Bidfloor.')
      }
      message()
    },
    copyList(type) {
      if (type === 'apps') {
        navigator.clipboard.writeText(this.adAppsBlacklistString)
        Vue.prototype.$notification.success({
          message: 'Ad Apps Blacklist copied to clipboard.',
        })
      } else if (type === 'brands') {
        navigator.clipboard.writeText(this.adDomainsBrandsBlacklistString)
        Vue.prototype.$notification.success({
          message: 'Ad Domains/Brands Blacklist copied to clipboard.',
        })
      } else if (type === 'pubIds') {
        navigator.clipboard.writeText(this.pubIdList)
        Vue.prototype.$notification.success({
          message: 'Pub ID List copied to clipboard.',
        })
      } else if (type === 'crids') {
        navigator.clipboard.writeText(this.cridList)
        Vue.prototype.$notification.success({
          message: 'CRID List copied to clipboard.',
        })
      }
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    domainHandler(val) {
      const publisher = this.ORTBpublisher.list.find(pub => pub.Id === val)
      const publisherPlatformDomain = publisher.PlatformDomain
      const publisherJsonDomain = publisher.SellersJsonDomain
      this.jsonDomain = publisherJsonDomain
      this.ptDomain = publisherPlatformDomain
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.PubIdList = this.pubIdListString
          values.CridList = this.cridListString
          values.AdAppsBlacklist = this.adAppsBlacklistString
          values.AdDomainsBrandsBlacklist = this.adDomainsBrandsBlacklistString
          this.sizesListMode === undefined ? values.SizesListMode = 0 : values.SizesListMode = 1
          values.BannerEnabled = this.bannerEnabled ? 1 : 0
          values.VideoEnabled = this.videoEnabled ? 1 : 0
          values.AudioEnabled = this.audioEnabled ? 1 : 0
          values.NativeEnabled = this.nativeEnabled ? 1 : 0
          values.PopEnabled = this.popEnabled ? 1 : 0
          values.WebEnabled = this.webEnabled ? 1 : 0
          values.AppEnabled = this.appEnabled ? 1 : 0
          values.NonEmptyReferer = this.nonEmptyRefererEnabled ? 1 : 0
          values.AutoOptimize = this.autoOpt ? 1 : 0
          values.PopAdmXml = this.popAdmXml ? 1 : 0
          values.RefererMatch = this.refererMatchEnabled ? 1 : 0
          values.IpMatch = this.ipMatchEnabled ? 1 : 0
          values.UaMatch = this.uaMatchEnabled ? 1 : 0
          values.DeviceTypes = this.activeDeviceTypes
          values.MinBidfloor = parseFloat(12)
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))
          const countries = []
          this.countriesList.forEach(el => {
            if (el.Enabled) {
              countries.push({
                CC: el.CC,
                BidAdjustment: 100,
              })
            }
          })
          values.Countries = countries
            .filter(country => country.Enabled)
            .map(country => country.CC)
          values.IabCategories = newArr

          this.$store.dispatch('rtbzone/CREATE_RTBZONE', {
            payload: {
              ...values,
              DefaultCPM: 1,
              Countries: countries,
            },
          }).then(response => {
            const id = response.Rtbzone.Id
            this.$router.push({ name: 'rtbzone-update', params: { id } })
          }).catch(error => {
            console.error('Error creating RTB Zone:', error)
          })
        }
      })
    },
  },
  created() {
    getIabCategories().then(response => {
      const resultArray = response.flatMap(obj => {
        const subnameArray = obj.Subcategories.map(subname => subname.Name)
        return [obj.Name, ...subnameArray]
      }).filter(name => name !== undefined)
      this.checkedKeys = resultArray
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
    })
    this.$store.dispatch('info/LOAD_COUNTRIES').then((res) => {
      res.forEach((el) => {
        this.countriesList.push({
          Country: el.Country,
          CC: el.CC,
          Enabled: false,
        })
      })
      this.populateCountry()
    })
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_SHORT')
    this.$store.dispatch('ORTBiplist/LOAD_IPLISTS')
    this.$store.dispatch('applist/LOAD_APPLISTS')
    this.$store.dispatch('domainlist/LOAD_DOMAINLISTS')
    this.domainRegExp = new RegExp(/^(?!https?:\/\/|www\.).*$/)
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}

.list-flex {
  display: flex;
  align-content: flex-start;
}

.select-width {
  width: 90%;
}

.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.custom-table th,
.custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line>>>li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
